<template>
  <div class="home-container">
    <div class="linear-bg"></div>
    <div class="home-scroll">
      <div class="banner">
        <swiper class="banner_swiper" :options="bannerSwiperOption">
          <swiper-slide v-for="(v, key) in banners" :key="key">
            <van-image class="banner_img" round :src="v.url">
              <template v-slot:loading>
                <van-loading type="circular" />
              </template>
            </van-image>
          </swiper-slide>
        </swiper>
      </div>
      <div class="hot-game">
        <div class="hot-title-div">
          <div>
            <span>{{ $t('index.task') }}</span>
          </div>
        </div>
        <div class="hot-items-div">
          <van-grid :border=false :column-num="3" :icon-size="20">
            <van-grid-item @click="toLottery(v.key, v.id)" v-for="(v, key) in gameitem" :key="key">
              <van-image class="game_item_img" :src="v.ico">
                <template v-slot:loading>
                  <van-loading type="circular" />
                </template>
              </van-image>
              <span>{{ v.name }}</span>
            </van-grid-item>
          </van-grid>
        </div>
        <!-- 合作平台 -->
        <div class="hot-title-div">
          <div>
            <span>{{ $t('index.hz') }}</span>
          </div>
        </div>
        <div class="hezuo-pingtai">
          <img src="img/home/hz01.png" alt="">
          <img src="img/home/hz03.png" alt="">
          <img src="img/home/hz05.png" alt="">
          <img src="img/home/hz06.png" alt="">
          <img src="img/home/hz07.png" alt="">
          <img src="img/home/hz08.png" alt="">
          <img src="img/home/hz09.png" alt="">
          <img src="img/home/hz14.png" alt="">
        </div>
          <div class="hot-title-div">
            <div>
              <span>{{ $t('index.task') }}</span>
            </div>
          </div>
          <div class="lunbo">
            <div class="lun-title">
              <span>Nama</span>
              <span>Telepon</span>
              <span>Sumbangan</span>
            </div>
            <div class="list">
              <div class="cc rowup">
                <div class="item" v-for="(item, index) in combinationArr" :key="index">
                  <span>{{ item.name }}</span>
                  <span>{{ item.number }}</span>
                  <span>{{ item.amount }}</span>
                </div>
              </div>
            </div>
          </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notice: this.$t("index.loading"),
      banners: [{}],
      basicData: [],
      gameitem: [{}, {}, {}],
      movielist_0: [{}, {}, {}, {}],
      movielist_1: [{}, {}, {}, {}, {}, {}, {}, {}],
      isLoading: false,
      movielistSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup: 1,
      },
      bannerSwiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        speed: 800,
        autoplay: true,
        coverflowEffect: {
          rotate: 50,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows: true
        }
      },
      ming: [
        "ALFINNY JELIE RUNTUNUWU",
        "SAINAL SULTAN",
        "HENOCH CHRISTOPHER",
        "NANDA",
        "MADIANTON SIMAMORA",
        "PUTRI",
        "ZAENAL SYAHBANI",
        "MUHAMAD  YELI",
        "PURWANDI NAZAM",
        "SUPRAWIRAWAN SIDAMPOY",
        "SUHARTINI",
        "LUKMAN HAKIM",
        "SULASTRI",
        "M.AKBAR ZULKARNAIN.Z",
        "DIKY ZULKARNAIN, ST",
        "HENDRIK RUSDIAN ARIFUDIN",
        "PRADITIA PRAMANA PUTRA",
        "FURY KOMALA DEWI",
        "YANU DANI MARFIYANTO",
        "LATIF SUKATMI",
        "BOBY CHANDRA",
        "ENRICO BRACLIN KUNDIMAN",
        "MUSYAROFAH",
        "PRADITYO ARIEF WICAKSONO",
        "PRASETIONO",
        "DINI RISWANTI",
        "ANTIKA SURI",
        "PARILAH",
        "AKHMAD MUHAZIR",
        "ANASTASYA WETI",
        "NOVITA WADJO",
        "SUPIYATUN",
        "DIKI WAHYUDI",
        "SARNO",
        "IR.MARTIYAWANTO",
        "NOVA LIAN SARI",
        "RASIM",
        "BUSTANUL ICHWAN B",
        "DEWI RATNA SARI",
        "TUKIYO",
        "YAHYA",
        "SARWONO",
        "ROKHIFAYATUN",
        "SUKIYATNO",
        "ZULKHARNAIM",
        "MINGGAS MONIM",
        "MICHAEL MARCELINO",
        "NAILA ZAHRAH",
        "LESTARI PRAGUSVITA",
        "MAY LIANAWATI",
        "HILDA ZULKARIMAH",
        "BERTIANA SARI",
        "SUPRAPTO",
        "ATHALLAH ZAIDAN",
        "SUBIYATI",
        "SUDIONO",
        "ASMAUL QUSNA",
        "KHUSNUL FITRI LISTIYANI",
        "YURINA NURAENI",
        "CACA ABDULAH",
        "ASMAWATI",
        "DEAFRINALDI",
        "ARIS SUGIARTO",
        "AGUS SOLEHUDIN",
        "GITA INDRIANI",
        "ALI IMRAN NASUTION",
        "M YUNUS",
        "JENNY INDRA SABILLA",
        "HARTI",
        "TRI YOGA FERDIMANTO",
        "MELANIA ROSIANA SUNARYA",
        "MUBAROH",
        "RITA SAPERLIN ZEGA",
        "ADE SUPARMAN",
        "FEBRYAN HELMUS T. MALELAK",
        "NURLAILAH HASIBUAN",
        "MUHIDIN",
        "GITA RISANTI",
        "SALWA NURJAMILAH",
        "NURIAH",
        "BI&#39;UL NOVITASARI",
        "SUYITNO",
        "SIENDY SETIAWAN",
        "FITRIA",
        "ANDRY, ST",
        "DEDI HERIANTO",
        "SERI HARTATI PANE",
        "TRIA DEWI SYIFA",
        "DWIKA IRFAN HERIANTO",
        "MANANG",
        "PUJI PURNOMO",
        "INAN KUSNADI",
        "SEKAR LILI YUANANDA",
        "HARDIMAN MAHARAJA",
        "HADA MAHMUDAH",
        "GIET ALEXIS LAMOH",
        "NIKEN NIDYARNA",
        "SERLIN",
        "KASIRI",
        "UPI SUPRIANI",
        "ELSYE ANDASIA",
        "EKA SULISTIANINGSIH",
        "RODIYAH",
        "DEWI PURNAMA SARI, SS",
        "AULYA KRISTYA",
        "NANDA APRILIA DWIJAYANTI",
        "FITRI LESTARI",
        "RISMA",
        "KASALLA LYNK UNO",
        "RATNAWATI",
        "YOPPY PUTRA",
        "KARNO SUHAENO",
        "SOLEMAN",
        "DWI CAHYONO",
        "SRI FERAWATI",
        "ANDRE PRAMANA TARIGAN",
        "MAT TUKRI",
        "FITRIYATIN AFRIE S.",
        "HANNY ZAFRULLAH",
        "DEDY DONOVAN SINURAT",
        "JHON PITER SIRAIT",
        "SLAMET BUDI HARNO",
        "JEVIN STEVINCENT",
        "LA KANANG",
        "M. ZULKARNAIN HASIBUAN",
        "EKA PUJIASTUTI",
        "IMELDA YASTI",
        "FELIX FREDERICK TAING SOGEN",
        "MARIAM SRI RAHMINI, S.PD, HJ",
        "IRMA HASNI NUR PADILAH",
        "INDRA PRASETYA",
        "YOLA MAYSARAH",
        "DHANI FEBRIANTO",
        "SRI WAHYUNI BR GINTING",
        "JULIANA",
        "THERESIA S. HAYOKO",
        "Rismawati Rasjid",
        "ERNI LIA",
        "OBETNEGO YON MELKIANUS WENIGERIMU",
        "SEPTIANA",
        "DELIA HERTIANTY PUTRI",
        "NENI ULANDARI",
        "SUKRIANTI",
        "TORINAH",
        "THERESIA AVILA WISANG",
        "PRANOTO",
        "MIRA JUWITA",
        "ANI SRI MULYATI",
        "KESSY YOLANDA RESTI",
        "ROMDANI",
        "NURUL HIDAYAH",
        "TUN HABIBAH",
        "MUCHTAR TANGKO",
        "ROSY INDRIYANI",
        "EDI WARSITO",
        "MADRUS",
        "NAILAH HIDAYAH",
        "SEPTIANINGSIH",
        "GEDE PUTU WIDYAISWARA",
        "AGUNG WAHYU NUGROHO",
        "SUHARTONO",
        "NANANG",
        "RATNA SARI",
        "CHANDRA WIJAYA",
        "KHOIRUL ANWAR",
        "DAMANHURI",
        "RATNAWATI",
        "HASISAH",
        "NAHRA RAHDINY",
        "SUYATI",
        "YESSI NOFITA",
        "UJANG ANDIKA",
        "NURIL HUDA",
        "DONI PRASENO",
        "SANTI RAHMAYANTI",
        "SINDRA UTAMA",
        "JURAIDIN",
        "NUR AINI OKTININGRUM ADINDA",
        "ALDA LUTHFIA ALFIANTI",
        "FRANSISKA APRILLINA SUSANTI",
        "SUNARTI",
        "SAIPUDIN",
        "MARDIYANTO, SH., MH",
        "MARIA MUTIARA INDA",
        "DENDEN JAMALUDIN",
        "NAUFAL ARIZ IKRAM",
        "HILDA PURNAMASARI",
        "NUR ANGGRAENI",
        "SUPARDI",
        "ALBERT W. HANYI",
        "BAMBANG DWIYANTARA",
        "PATRILISIA APOLONIA MARGARETHA PALLA",
        "BENEDIKTUS ASRI",
        "DANNY SETYAELVINA MEYSHITA",
        "AGUNG PRASETYO",
        "WILHELMUS BATE NEDE, STP",
        "KATAM YS",
        "M. KHOTIB",
        "ELVIRA OLIVA TIDHA",
        "ZULAEKA RAHMA NEYZA",
        "SELVI ANASTASIA",
        "FEBRI ABDI WALUYO",
        "RADA AMENIA",
        "MUHAMMAD SIROJUDDIN",
        "MUHAMMAD REGI",
        "AHMAT PRAMONO PUTRA",
        "NITA SETIAWATI",
        "NUR AENI PUJI LESTARI",
        "Juma Ali",
        "DEWI ANGGIYANI HAKSONO",
        "YAEDIH",
        "ANJAS SANUSI",
        "RINI",
        "DESRINAWATI",
        "BAYU APRISYANTES",
        "SUCI LESTARI",
        "SITI UMAH",
        "HERY PURNOMO",
        "DIANIKA WIDYA SALSABILLA",
        "CECEP MUHAMMAD ANWAR FAUZI",
        "SOPIA ARIANI",
        "VERAWATY BASIRU",
        "YULIANTI",
        "DEWI ANDRIYANI",
        "MICHELLE SINJOBANTAENG",
        "YOE MULYONO",
        "ELDIAN SYAH KARUNIA PUTRA",
        "RINI SARAGI",
        "MISRA",
        "HARDI PRATAMA",
        "FIRNA FIRANI",
        "SUTARMO",
        "MARYANTO",
        "ADI SUNARYO",
        "KENDY JULIANTO",
        "SULISTYOWATI",
        "JOHANES OXAVASCO JULIUS PATRIX MAHAR",
        "ENDRA YANI",
        "ADE ROHIM SAPUTRA",
        "SRI IDA LUMONGGA.B.",
        "PITRIANI",
        "MUHAMMAD AZHAR ASY&#39;ARI",
        "MANNA SALWAH",
        "MOH RAMDHAN SOEPANDI",
        "LINDA MARTINA",
        "NURHALIMAH SIHOMBING",
        "SITI MUALIMAH",
        "SALWA YASMIN THALIB",
        "MOH. ALI",
        "EGI DIANJAR",
        "AGUSRIYANTO",
        "DESI RAHAYU",
        "RAYI NURUL FIKRI",
        "SINCE FARIAN",
        "SHINTA YULIANA",
        "SRI RESKI",
        "LILIS SARTIKAH",
        "SITI DJUARIAH",
        "IMAM MULYADI",
        "RAFIUDDIN NONCI",
        "NIKEN SARASWATI DWI KARTIKA",
        "AYU PRATAMANINGRUM",
        "RONALD",
        "ANDEREAS",
        "NIMUN",
        "DESI PERMATA SARI",
        "ARBADI",
        "PUTRA YUANDA",
        "TONI WIJAYANTO",
        "RONAL SIMANULLANG",
        "SULASTRI",
        "TIAMORINA TAMPUBOLON",
        "AININGSIH",
        "DAMUDIN",
        "SELPIA MONIKA HUTAJULU",
        "IRMA SURYANI",
        "HOENG JAT BING",
        "FLORENTINA INA WEDA",
        "ANITA AFIYATUN NIKMAH",
        "RISAL NOVANDAR KURNIAWAN",
        "SUSANTI BARA",
        "HARTINI",
        "ELGA GEMIDIA",
        "VANTRI PIETER KELELUFNA",
        "MICHAEL ROBINTANG M.R.SITANGGANG",
        "NATASHA ALEXANDRA",
        "SUTAN RAVEEN NATTA PRIMADI",
        "WAHYUDI ZULKARNAIEN",
        "ANGGA FITRA",
        "BAMBANG KURDIANSYAH",
        "TRI WAHYUNI",
        "SITI FATIMAH",
        "AMARATIN",
        "REGINA TUNJUNG DIAZMITA",
        "LAYLI ISTIQOMAH",
        "MARSIYEM",
        "AGNES TITERLIE",
        "ROHANA",
        "RENI AFRIANI ANTIKA",
        "MARIATUN",
        "ESY SARTIAH S",
        "RAKA FAIZAL AKBAR",
        "KARTONO",
        "DIMAS DWI PRASETYO",
        "TANTY ERLINA",
        "RIZKY HARIMAHWAN",
        "M ASLAM GINTING",
        "DEDI",
        "DIAH NUR FITRIA",
        "SUGIANTO",
        "AGUSTIN FARADILLA KUSUMA",
        "DONI SINATA PUTRA",
        "RINA GUSTIANA",
        "ENDANG AWALUDIN",
        "SATURNINO ANICETO SERAUNG",
        "YUNUR YUNAIDI",
        "AHMAD JUHAENI",
        "IRFAN BIN HUSAIN",
        "PRIHATIN DARSINI",
        "Ir.ANTON ISWORO",
        "WAHDANIAR",
        "ATIKA WIDYASARI",
        "GUNAWAN AGUSTEJO SUKMONO",
        "SUYUD, S.PD.I",
        "ISKANDAR",
        "FRIEDA",
        "YUNIORREZA MELANDO BIETHS",
        "MOH SUGENG",
        "RICO JEPRI MU&#39;ANAM",
        "AGUS SUSILA WARDAYA",
        "AGUS RIMBA",
        "FATHNI ULYA",
        "MADE DION ADI NEGARA",
        "SESARIUS ROMANSAH SUHARDI",
        "ERIKA ARITONANG",
        "YOGI NOVITER",
        "TANIA OCTAVIA",
        "SAMSI",
        "SATRAN",
        "NI LUH TIRTASARI",
        "ALYA ZAHARA PUTRI",
        "NOVI NURBAETI",
        "LISTINA",
        "WIWI",
        "PARSONI",
        "SRI UTAMI",
        "SAID ANDANI",
        "DEDY RAHIM",
        "EKO IRVANANI",
        "NATALIA TIFANI",
        "ERMITA SARI",
        "VINCENTIUS ERICK RUDYANTO H.",
        "DIMAS PRAMUDYA SUTIAWAN",
        "EMILIANA LAKSMITA DEWI",
        "Fu Su Ning Alias Fery Alian",
        "RISMA BETTY BR SINAMBELA",
        "WULANDARI",
        "HUSNUL KHOTIMAH",
        "KARWONO AL SOHIRIN",
        "MEIYAROH",
        "ULUL HIKMAH",
        "AFIF NOR AINANINGSIH",
        "YANDHI",
        "IIS RASMAWATI",
        "NOPITA SARI",
        "ISWANTO",
        "MASFUAH",
        "FARID MAULANA",
        "SUNITA DEWI SARAGIH",
        "CUNAYAH",
        "B.MUSRIFAH",
        "KAYLA FAIZA WARD",
        "MALIKAH",
        "ROY PADJALO",
        "PUTRI LIA ROSA",
        "SUKARSE",
        "BALYA AKBAR ARAFAT",
        "HARTONO",
        "AGUS FIRMAN SYAH",
        "ADINDA NISTRIA NADAPDAP",
        "RIVAL",
        "FIRLIAN RAMADHANTA PRATAMA",
        "AHMAD MUAZIS",
        "INTAN MARCELINA",
        "EKASWINA KEMBAREN",
        "TESA RAHMADITA",
        "MUHAMMAD IQBAL",
        "AGUS SUPRIYADI",
        "RIDWANSYAH DUMANG",
        "ANISA FAHRIANI, SE",
        "DWI DESTI SULISTIAWATI",
        "SRI LESTARI",
        "MUKHAMAD UBAIDILAH",
        "YOSEP JANGKUR",
        "DUWI SRI WAHYUNI",
        "LINA YUNIATI",
        "EKA ADI SULISTYONO PUTRA",
        "NI PUTU LISNIA WATI",
        "WAHYU RAHMADHANI",
        "MOHAMAD FADIL RAKHMAWAN",
        "AISYA PUTRI MUKHOLIFATUN ELMI",
        "WIYANTO",
        "NABILLA FITRIA CAHYANINGRUM",
        "MASTOMI",
        "DWI CAHYO SETIAWAN",
        "NAUFAL HAFIDZ RIZKILAH",
        "MEILLIANA SITOMPUL",
        "ANISA MIRAHNTY",
        "RIZKI ARIANDY",
        "NISRINA KALTSUM FATHIN",
        "HERLINA",
        "SUTARYO",
        "SUTRISNO",
        "ZHIA ZAKIATU ZAHRA",
        "NI LUH PUTU SUDARWATI",
        "SUKRI SYAM",
        "ZAINAL",
        "DYAH SUSANTI",
        "RIFKY ESA PUTRA DAHAR",
        "LILIN PURWATI",
        "HILDA RAHMA MAULIDA",
        "IRFAN RAHMAD WIDIUTOMO",
        "JENAB",
        "ANGELI MANES",
        "KUSUMA CAHYADI",
        "TISNA SENJAYA",
        "MUHAMMAD IQBAL HR.SH",
        "SAEP",
        "FIRZA AYU DWITARI",
        "NIFA",
        "RISKA ARISANTI",
        "GALIH SAKTI NUGROHO",
        "YULIA EKA PRATIWI",
        "YULI SETIAWATI",
        "SUPRIHATIN",
        "SUNITA",
        "RIZKY WIJAYA",
        "REJO SUWOKO",
        "KORLIA DEBORAH",
        "ACH. MUZAKKY JALY",
        "IBRAHIM",
        "INTAN FERA YUNITA",
        "YUSMIANNI SIREGAR",
        "SUYATONO",
        "BJ. TONIMAN",
        "NESIN",
        "SUGIARTI",
        "HERU BIMO SAMUDRO",
        "SELAMET MARYADI",
        "BAGA HARIANJA",
        "NAFIAH",
        "SAENAL",
        "ALI MAHMUDI",
        "THOMAS RANO",
        "ANI",
        "DIYOUVA CHRISTA NOVITH",
        "ATIKA FAUZIYYAH MUMTIHAN",
        "ARDIANSYAH DWI CAHYO",
        "M KHOIRUL ANAM",
        "PITRIYANIH",
        "HUTAMI RINDYASTUTY",
        "RAHMAD HIDAYAT",
        "HARINI",
        "LIA MUSTIKA",
        "WANDA HAMIDAH VAHRIZAL",
        "A JAMIL",
        "SUSAN ANDRIANI",
        "RINI SHINTA JATI",
        "DASRIN",
        "ERFAN EFENDI",
        "MUHAMAD ASOHBIRIN",
        "DJABARUDIN",
        "NUKI EKOWATI",
        "ROBERT FEBRIAN GHOZARI",
        "JOSEPHINE LOUISE WATUNA",
        "DEWI RATNASARI",
        "IDUL DHARMAWAN TANIZAR",
        "ASEP SAEFUR ROKHMAN",
        "SUGIARTI AGUSTINA",
        "NI PUTU GITA WIDHIANI",
        "SATIK ROHAMA WATI",
        "LIE NYAT PHIN",
        "GUNAWAN TABA",
        "DEA PERMATASARI",
        "DANNI MARZELY",
        "JUSTIN CHRISTIAN WILAYATA",
        "KASIYATI",
        "LIMRET",
        "MOH. RIFALDI KASIM",
        "WILLY ZUHELSYA",
        "YAYA SETIADI",
        "KASIYAH",
        "RUSMINAH",
        "ANISATUN MUTMA INAH",
        "JULI AZWAR",
        "KHOIRUDIN",
        "NURUL ARIYANI"
      ],
      numberArr: [
        "81212308409",
        "85311652764",
        "89637070634",
        "82291118720",
        "81263283677",
        "85822315190",
        "82112391679",
        "81295080716",
        "81270082178",
        "82290896592",
        "89661838273",
        "81388499039",
        "81330294272",
        "82176632438",
        "81373886012",
        "85354534607",
        "85213696000",
        "85291553886",
        "85740086236",
        "81317464364",
        "85363216089",
        "87803447956",
        "85726015892",
        "85386429325",
        "81331693957",
        "81222821993",
        "89689124493",
        "81293269631",
        "85349682416",
        "82157649395",
        "81344150038",
        "81259680509",
        "81285689950",
        "85770767602",
        "85716057999",
        "85382011937",
        "88888888888",
        "82122662390",
        "85785069672",
        "81390281299",
        "82111975914",
        "81293287364",
        "81316400044",
        "82174572898",
        "82122108880",
        "82114459783",
        "81377877062",
        "81331691093",
        "87788948373",
        "85100411078",
        "82287142389",
        "82311251958",
        "81278762161",
        "85775764748",
        "82177444835",
        "83162214602",
        "83833460348",
        "85159695977",
        "87845222378",
        "88218124536",
        "81529969987",
        "81268224112",
        "81382037484",
        "81256177601",
        "85659635737",
        "81275863450",
        "82191317344",
        "85706115899",
        "82126152578",
        "81225002394",
        "87824177009",
        "85385480480",
        "81383413646",
        "85394250095",
        "81337838283",
        "81397491916",
        "81289851701",
        "82240061242",
        "85320946858",
        "81212054081",
        "85954514304",
        "81380717071",
        "81283167083",
        "81367612340",
        "81333565755",
        "85236562973",
        "85260774746",
        "82236745310",
        "81296113070",
        "81216664389",
        "85803400219",
        "85890588385",
        "89508418215",
        "82283832841",
        "81258266246",
        "81325000771",
        "82125902530",
        "85326970680",
        "87726288001",
        "85715376373",
        "81340232788",
        "81373345335",
        "85712298245",
        "81350104164",
        "82150662908",
        "82251098704",
        "83805009791",
        "82347281756",
        "81515613803",
        "85271439900",
        "85363914823",
        "85629811084",
        "89604992110",
        "87884742600",
        "81253258269",
        "82299045539",
        "81551537361",
        "85336533456",
        "81333341345",
        "81265315736",
        "82274182625",
        "89526979933",
        "81294360683",
        "81251799902",
        "81277750228",
        "81217162711",
        "85238426920",
        "81340396409",
        "88705189771",
        "82262446156",
        "85220702818",
        "81365122165",
        "82232333392",
        "82165600671",
        "82361922246",
        "82359499911",
        "85396833151",
        "81271301173",
        "81297350140",
        "87875843851",
        "81222336177",
        "87863457959",
        "81311114080",
        "81339778467",
        "82197898707",
        "81268627475",
        "82200000000",
        "82242081578",
        "81261489536",
        "85751441884",
        "89608554092",
        "85282902167",
        "81258835317",
        "89513915672",
        "8122528990",
        "81353148745",
        "89606440895",
        "82221429151",
        "85258684042",
        "81776962447",
        "85326363777",
        "89662006228",
        "85779190964",
        "82159274880",
        "85368854633",
        "81210434565",
        "81253966421",
        "81241208804",
        "81344413913",
        "85157999298",
        "81282838479",
        "82271332370",
        "85246520521",
        "81221547927",
        "85759008420",
        "81212085718",
        "82357501231",
        "81280107897",
        "85740575761",
        "89657178829",
        "85330124495",
        "81939447611",
        "82179929696",
        "81296565576",
        "85720188472",
        "85359607022",
        "82134497519",
        "81573736913",
        "81554556524",
        "81349288127",
        "85216178066",
        "81339202015",
        "81339510601",
        "87711102321",
        "85608935120",
        "81331621853",
        "81267835135",
        "85852780973",
        "82145344098",
        "82298161376",
        "83876465999",
        "81138083429",
        "89676471648",
        "81330188232",
        "81314835792",
        "81326276772",
        "82238626130",
        "85741094783",
        "89523675761",
        "87834326376",
        "82111151478",
        "89698115319",
        "85659012994",
        "81270990284",
        "82386836657",
        "81776493910",
        "89519271305",
        "81365533899",
        "82338282078",
        "89531704123",
        "85338887416",
        "85240430327",
        "85341955958",
        "85741383612",
        "81299080866",
        "81331406220",
        "85633558877",
        "85360099237",
        "85208522845",
        "81286898949",
        "85732418982",
        "81329482784",
        "81268920058",
        "87878755455",
        "81220884295",
        "81381178327",
        "81256585473",
        "85268352671",
        "81287439237",
        "81386661904",
        "82148893050",
        "85731735666",
        "85359966198",
        "87724107829",
        "82283622114",
        "85279209753",
        "81217856075",
        "82277888230",
        "82252470574",
        "85710018100",
        "82111783837",
        "81285346307",
        "89653977182",
        "85348707408",
        "87777781997",
        "82120050205",
        "85777597745",
        "81219838565",
        "82131751977",
        "81524922730",
        "81357036471",
        "82138258755",
        "87777110116",
        "82252805506",
        "87884300394",
        "85383001100",
        "85889434250",
        "82285690044",
        "81398885941",
        "81380604412",
        "85850318818",
        "83803066368",
        "85955291956",
        "87760737574",
        "81343138919",
        "83890855010",
        "81235297777",
        "81364072438",
        "81231079818",
        "81335120975",
        "81292678853",
        "81346200073",
        "85659159288",
        "81304940799",
        "81370800846",
        "85899989081",
        "82221212297",
        "89506198897",
        "81574001811",
        "85722005885",
        "82179428816",
        "83136377683",
        "81545026299",
        "85691474434",
        "82334321095",
        "85225050049",
        "82239705190",
        "81275342203",
        "82384382452",
        "85246369102",
        "81318023798",
        "85524944449",
        "81331412397",
        "81327793774",
        "82125674093",
        "89516702620",
        "81264355704",
        "85399721336",
        "82223337892",
        "82395242501",
        "89604489772",
        "82253554374",
        "81391239891",
        "82217370955",
        "81211638115",
        "81381522963",
        "85691769534",
        "81342655282",
        "81329509854",
        "85332549449",
        "81340150617",
        "85878662083",
        "87812180567",
        "81228355656",
        "87806960611",
        "81290440879",
        "82199425441",
        "85733868400",
        "82332401982",
        "81281314999",
        "85218795888",
        "88279575041",
        "81936261161",
        "81246080953",
        "82272480389",
        "81273145627",
        "87884389551",
        "82198888512",
        "81385576142",
        "87814990655",
        "81534353385",
        "85215130537",
        "81363102997",
        "81311046016",
        "81352747062",
        "85346845589",
        "83195637727",
        "81243020004",
        "82213720554",
        "81281047054",
        "82320305457",
        "81246976024",
        "85746114025",
        "82113071179",
        "81368431930",
        "81375117710",
        "85329208709",
        "85930979700",
        "83897693455",
        "85226131483",
        "85921193593",
        "81210874039",
        "81265698267",
        "83169191200",
        "85710148044",
        "81249493027",
        "87839817280",
        "81368889874",
        "82362291816",
        "83113418167",
        "82257155496",
        "85163694432",
        "81226548790",
        "81245479976",
        "82281326174",
        "85341408836",
        "82298569523",
        "85712863751",
        "85390942422",
        "81239195650",
        "81313268581",
        "87853123353",
        "85645323083",
        "82299667874",
        "85261408789",
        "82377743225",
        "87806913801",
        "82213974512",
        "82191827597",
        "82351080395",
        "82284442082",
        "81297418910",
        "85161173836",
        "85256372570",
        "85349621178",
        "81254589350",
        "81398031276",
        "85942941759",
        "81802681638",
        "87818202404",
        "85730150969",
        "85212617508",
        "85856630818",
        "81997945463",
        "82331336976",
        "81297829625",
        "85972834386",
        "85943151715",
        "85368147910",
        "81329694309",
        "83899345466",
        "81389167622",
        "81395163224",
        "85783467918",
        "85333477477",
        "81347688212",
        "85381443425",
        "85292760607",
        "85823986609",
        "85234340930",
        "81230068184",
        "82247654792",
        "81279553088",
        "81216602270",
        "81231193909",
        "81316493888",
        "81355581888",
        "85314592585",
        "82176700760",
        "82349454739",
        "85814520872",
        "89608617167",
        "85799143783",
        "81334128884",
        "85215099440",
        "81271991304",
        "81322489340",
        "82244235680",
        "81224002824",
        "87750822677",
        "85890971982",
        "81290930119",
        "82273444277",
        "81328335988",
        "82358259537",
        "87820745971",
        "81287380178",
        "85156598919",
        "85711346326",
        "82161799879",
        "82153667249",
        "81805703522",
        "81280393650",
        "82195622666",
        "85347742093",
        "82147544050",
        "81242810411",
        "88227832131",
        "83128286158",
        "85697372330",
        "82136928384",
        "85254049288",
        "81803333426",
        "83109509355",
        "89603217861",
        "85780806946",
        "85238099733",
        "87733811103",
        "82378804286",
        "82166234555",
        "82277338823",
        "82198171604",
        "82141572589",
        "81346497605",
        "81241725526",
        "82251511785",
        "81809808899",
        "81332144440",
        "81219096569",
        "87862556120",
        "85236526036",
        "85319393778",
        "85822494315",
        "85704052408",
        "81212395253",
        "82187677570",
        "87785813450",
        "85823236579",
        "85311812089",
        "85271633552",
        "82115409844",
        "81347399326",
        "81263441614",
        "81802200299",
        "81378529797",
        "82245863368",
        "81332473218"
      ],
      combinationArr: []
    };
  },
  methods: {
    // 组合滚动
    combination() {
      for (let index = 0; index < 1000; index++) {
        // 名
        let mingText = this.ming[Math.floor(Math.random() * this.ming.length)];

        // 手机号码
        let numberText01 = this.numberArr[Math.floor(Math.random() * this.numberArr.length)];
        let numberText02 = Math.floor(Math.random() * (999999 - 111111 + 1)) + 111111;

        // 金额
        let amountText = Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;

        this.combinationArr.push({
          name: mingText,
          number: numberText01 + numberText02,
          amount: "Rp" + amountText + "K"
        })
      }

      console.log(this.combinationArr)



    },
    toLottery(key, id) {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' })
      } else {
        this.$router.push({ path: '/Lottery?key=' + key + "&id=" + id })
      }

    },
    toPlayVideo(id) {
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' })
      } else {
        this.$router.push({ path: '/PlayVideo?id=' + id })
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.getBasicConfig();
        this.isLoading = false;
        this.$toast("Làm mới thành công");
      }, 500);
    },
    getBasicConfig() {
      this.$http({
        method: 'get',
        url: 'sys_config'
      }).then(res => {
        this.basicData = res.data;
        this.getNotice(this.basicData);//获取公告
        this.getBanner(this.basicData);//获取banner
        this.getGameItem();//获取首页游戏列表
        this.getMovieList_0(this.basicData);//获取首页视频0
        this.getMovieList_1(this.basicData);//获取首页视频1
      })

    },
    getNotice(data) {
      this.notice = data.notice;
    },
    getGameItem() {
      this.$http({
        method: 'get',
        url: 'lottery_hot'
      }).then(res => {
        this.gameitem = res.data
      })
    },
    getMovieList_0(data) {
      this.movielist_0 = data.movielist_0
    },
    getMovieList_1(data) {
      this.movielist_1 = data.movielist_1
    },
    getBanner(data) {
      this.banners = data.banners;
    }
  },
  mounted() {
    this.combination()
  },
  beforeDestroy() {

  },

  created() {
    this.getBasicConfig();
  }
}

</script>

<style lang='less' scoped>
@notice-bar-size: 30px;
@movie-list-item-bottom-size: 25px;

.linear-bg {
  height: 200px;
  background: linear-gradient(90deg, #f6a883, #fe8856);
}

.home-container {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-color: #ffe5c84d;
}

.linear-gradient {
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));
}

::v-deep .van-notice-bar__left-icon,
.van-notice-bar__right-icon {
  min-width: 40px;
}

.notice-swipe {
  width: calc(100% - 50px);
  height: 85px;
  font-size: @notice-bar-size;
}

::v-deep .van-icon-bullhorn-o::before {
  transform: scale(2.5);
}

.banner {
  width: 100%;
  margin-top: -23%;
}

.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    height: 300px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}

::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

.banner_img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.hot-game {
  width: 100%;
}

.hot-title-div {
  width: calc(100% - 50px);
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hot-title-div>div:first-child {
  width: 430px;
}

.hot-title-div div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.hot-title-div>div:nth-child(2) span {
  font-size: 20px;
  color: #000;
}

.hot-title-div>div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  font-weight: 700;
  color: #000;
}

.hot-title-div>div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 28px;
  font-weight: 700;
  color: #000;
}

.hot-title-div>div:nth-child(2) span {
  font-size: 25px;
  color: #000;
}

.hot-title-div>div:first-child span:before {
  content: "";
  display: block;
  width: 5px;
  height: 30px;
  background-color: #775fd9;
  border-radius: 1px;
  margin-right: 5px;
}

.hot-game .hot-items-div {
  margin-top: -3px;
}

.hot-game .hot-items-div span {
  margin-top: 10px;
  font-size: 28px;
  color: #000;
  text-align: center;
}

.hot-recommend {
  width: 100%;
  flex: 1;
  background-color: #f2f2f5;
}

.movie_swiper {
  .swiper-slide {
    width: 80%;
  }
}

.movie_list_0 {
  width: calc(100% - 50px);
  margin: 0 auto;
}

.movie_cover {
  border-radius: 10px;
  width: 550px;
  height: 330px
}

.movie_list_0 .movie-list-item-bottom {
  position: relative;
  width: 550px;
  bottom: 43px;
}

.movie_list_0 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, .4);
}

.movie_list_0 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
  font-size: 28px;
  color: #fff;
}

.movie_list_0 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
}

.movie_list_0 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
}

.movie_list_0 span {
  font-size: 30px;
}

.movie_list_1 {
  display: flex;
  width: calc(100% - 50px);
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.movie_list_1 .movie-list-item .cover_img {
  border-radius: 10px;
  width: 335px;
  height: 290px;
}

.home-scroll {
  padding-bottom: 100px;
}

.movie_list_1 .movie-list-item {
  margin-bottom: -10px;
}

.movie_list_1 .movie-list-item-bottom {
  position: relative;
  width: 335px;
  bottom: 42px;

}

.movie_list_1 .movie-list-item:nth-child(odd) {
  margin-right: 25px;
}

.movie_list_1 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, .4);
}

.movie_list_1 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}

.movie_list_1 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
  height: 35px;
}

.movie_list_1 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}

.movie_list_1 .movie-list-item .movie-time-div span:last-child {
  overflow: hidden;
  white-space: nowrap;
  width: 0px;
  padding-right: 110px;
  font-size: 22px;
}

.movie_list_0 .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 350px;
  padding-left: 10px;
  font-size: 25px;
}

.hot-recommend-more {
  width: 100%;
  padding-bottom: 20px;
  text-align: center;
  color: #000;
  font-size: 25px;
}

.hot-items-div .game_item_img {
  width: 100px;
  height: 100px;
}

.van-grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-around;
}

::v-deep .hot-items-div .game_item_img .van-image__img {
  border-radius: 20px;
  object-fit: cover;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

.movie-list-item {
  img {
    object-fit: cover;
  }
}


.productProcess {
  width: 550px;
  height: 415px;
}

.loading_div {
  color: #eee;
  padding-top: 100px;
}

.title_div {
  width: 100%;
}

.table_body {
  width: 100%;
  margin-top: 15px;
}

.table_th {
  width: 100%;
  display: flex;
  height: 40px;
  line-height: 40px;
}

@keyframes rowup {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(0, -15000px, 0);
    transform: translate3d(0, -15000px, 0);
  }
}
.lunbo {
  position: absolute;
  bottom: 130px;
  top: 940px;
  width: 100%;
  overflow: hidden;
}

.list {
  width: 96%;
  margin: 20px auto;
  position: relative;
  overflow: hidden;
}

.list .rowup {
  -webkit-animation: 260s rowup linear infinite normal;
  animation: 260s rowup linear infinite normal;
  position: relative;
}

.item {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-around;
}

.item span {
  height: 100%;
  line-height: 40px;
  float: left;
  font-size: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  text-align: center;

  &:nth-of-type(1) {
    width: 36%;
  }

  &:nth-of-type(2) {
    width: 25%;
  }

  &:nth-of-type(3) {
    width: 30%;
  }
}

.lun-title {
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.lun-title span {
  height: 100%;
  line-height: 60px;
  float: left;
  font-size: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  word-break: break-all;
  font-weight: 900;

  &:nth-of-type(1) {
    width: 35%;
  }

  &:nth-of-type(2) {
    width: 29%;
  }

  &:nth-of-type(3) {
    width: 30%;
  }
}

.hezuo-pingtai {
  width: 100%;
  height: 200px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}

.hezuo-pingtai img {
  width: 22%;
  display: block;
  margin: 6px 0px;
}
</style>
<style>
.van-grid-item__content {
  background: #ffe5c800;
}
</style>