<template>
    <div class="custom-modal-wrap" v-if="isShow">
        <div class="custom-modal" :style="{ width }">
            <div class="custom-modal-content">
                <div class="custom-modal-header">
                    <div class="title">{{ title }}</div>
                    <div class="modal-close" @click="handleCancel">
                        <span class="van-badge__wrapper van-icon van-icon-cross"></span>
                    </div>
                </div>
                <div class="custom-modal-body">
                    <div class="alarm-info">{{ content }}</div>

                </div>
                <div class="movie-dialog-btn" style="padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;">
                    <button type="button"
                        class="van-button van-button--default van-button--normal " style="width: 200px;
    height: 30px;
    border-radius: 14px;
    background-color: #775fd9;
    font-size: 14px;
    color: #fff;" @click="handleConfirm">
                        <div class="" >
                            <span class="van-button__text">{{ okTg }}</span>
                        </div>
                    </button>
                    </div>
                <div class="custom-modal-footer">
                    <div type="primary" @click="handleCancel" v-if="type =='confirm'">{{ cancelText }}</div>
                    <div type="primary" >{{ okText }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "customModal",
        data() {
            return {
                //调用的时候里面的配置除了content外其他都为非必填，不传以默认项为准
                isShow: false, //控制显隐
                title: "Xem video", //标题
                type: "confirm", //类型
                content: "Qúy khách vui lòng liên hệ chăm sóc khách hàng để được hỗ trợ nâng cấp thể thành viên", //内容
                okText: "",
                okTg:"LIÊN HỆ VỚI EM",
                cancelText: "X",
                width: "75%", //百分比或者字符串
            };
        },

        methods: {
            handleConfirm() {
                // window.location.href = "https://t.me/CSKH_L0VE876"
                window.location.href = "https://t.me/gamgam2323"
        

            },
            handleCancel() {
                // window.location.href = "#/home"
                this.isShow = false;
                if (this.onCancel) {
                    this.onCancel();
                }
            },
        },
        mounted() {
            let that = this;
            that.$nextTick(() => {
                document.addEventListener("keyup", function(e) {
                    if (e.keyCode == 27) {
                        that.isShow = false;
                    }
                });
            });
        },
    };
</script>

<style lang="less" scoped>
    .custom-modal-wrap {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //   display: flex;
        overflow: auto;
        //   justify-content: center;
        //   align-items: center;
        z-index: 999999;
        outline: 0;
        background-color: rgb(0 0 0 / 70%);
    }
.movie-dialog .movie-dialog-btn{
    height: .8rem;
}
.movie-dialog .movie-dialog-btn button{
    width: 5.86667rem;
    height: 100%;
    border-radius: .4rem;
    background-color: #775fd9;
    color: #fff;
}
    .custom-modal {
        width: auto;
        margin: 0 auto;
        position: relative;
        outline: 0;
        top: 30%;
    }

    .custom-modal-header .modal-close span {
        /* border: 1px solid #eeeeee; */
        padding: 0.267vw 1.067vw;
        border-radius: 0.533vw;
        cursor: pointer;
        color: white;
        font-size: 30px;
    }

    .custom-modal-content {
        position: relative;
        background-color: #ffffff;
        border: 0;
        border-radius: 20px;
        background-clip: padding-box;
        box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
    }

    .custom-modal-header {
        background: linear-gradient(90deg, #f6a883, #fe8856);
        border-bottom: 10px;
        height: 100px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        line-height: normal;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 16px;
    }

    .custom-modal-header {
        .title {
            font-size: 30px;
            color: #fff;
            height: auto;
            line-height: normal;
        }

        .modal-close {
            white-space: nowrap;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            color: #888888;

            span {
                // border: 1px solid #eeeeee;
                padding: 2px 8px;
                border-radius: 4px;
                cursor: pointer;
            }
        }
    }

    .custom-modal-body {
        padding: 0 20px;
        font-size: 30px;
        line-height: 1.5;
        color: #c24491;
        margin-top: 20px;
    }

    .custom-modal-footer {
        font-size: 20px;
        color: #c24491;
        border-top: none;
        text-align: center;
        padding: 0px 18px 40px 18px;
        display: flex;
        justify-content: center;
        align-items: center;

        button:not(first-child) {
            margin-left: 10px;
        }
    }
</style>
